<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <ayl-main title="车辆信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>车牌号码:</span>
            <span> {{ form.plateNumber ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车辆归属:</span>
            <span> {{ form.carBelong ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车辆类型:</span>
            <span> {{ form.carType ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车辆状态:</span>
            <span> {{ form.carStatus ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>设备号:</span>
            <span> {{ form.deviceName ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>所属车队:</span>
            <span> {{ form.teamName ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>品牌型号:</span>
            <span> {{ form.carBrand ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车辆自重:</span>
            <span> {{ form.carSelfWeight ||'- -' }}t</span>
          </div>
          <div class="contents">
            <span>车辆载重:</span>
            <span> {{ form.calibrationTon ||'- -' }}t</span>
          </div>
          <div class="contents">
            <span>驾驶员:</span>
            <span> {{'- -' }}</span>
          </div>
          <div class="contents">
            <span>身份证号:</span>
            <span> {{ '- -' }}</span>
          </div>
          <div class="contents">
            <span>手机号码:</span>
            <span> {{  '- -' }}</span>
          </div>
        </div>
      </ayl-main>
      <ayl-main title="油量骤减信息" :isCollapse="false">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>油量骤减开始时间:</span>
            <span> {{ form.startTime|str2ymdhm}}</span>
          </div>
          <div class="contents">
            <span>油量骤减结束时间:</span>
            <span> {{ form.endTime|str2ymdhm}}</span>
          </div>
          <div class="contents">
            <span>油量骤减量:</span>
            <span> {{ form.startFuel ||'- -' }}L</span>
          </div>
        </div>
      </ayl-main>
      <div class="tac" style="margin-top: 40px">
        <el-button style="margin-left: 30px" @click="$router.push('/oil-drop/oil-drop')">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: '油耗管理', path: ''},
          {name: '油量骤减', path: '/oil-drop/oil-drop'},
          {name: '查看详情', path: ''},
        ],
        form: {},
      }
    },
    methods: {},
    async mounted () {
      this.form = await this.$api.getOilEventDetailInfo({
        eventType: '油量骤减',
        plateNumber: this.$route.query.plateNumber,
        startTime: this.$route.query.startTime,
      })
    }
  }
</script>
<style lang='sass' scoped>
</style>
